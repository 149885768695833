<template>
  <!-- 关键词 文章列表 -->
  <div class="container">
    <!-- 导航 -->
    <V-ArticleNav class="headClass" :navList="navList"></V-ArticleNav>
    <div class="gWbc title"> 
      发布数字产品加工
    </div>
    <div class="gWbc basicInfo">
      <div class="bi_top_btn">填写基本信息</div>
      <div class="typeIn" style="margin-top:30px">
        <div class="inLeft">
          <span>*</span>
          <span>任务名称：</span>
        </div>
        <div class="inRight">
          <el-input class="right_input" v-model="release.taskname" @input="textLength" @blur="textRepetition"
           placeholder="请输入30字内的任务名称"></el-input>
          <div class="rightTs">提示：最多输入30个字，且任务名称不能重复</div>
        </div>
      </div>
      <div class="typeIn">
        <div class="inLeft">
          <span>*</span>
          <span>任务简介：</span>
        </div>
        <div class="inRight">
          <el-input type="textarea" v-model="release.describe" resize="none" class="right_input" style="height:100px"
          placeholder="请输入任务简介"></el-input>
        </div>
      </div>
      <div class="typeIn" style="padding-top:10px;">
        <div class="inLeft">
          <span>*</span>
          <span>选择加工类型：</span>
        </div>
        <div class="inRight" style="width:416px;padding-top:10px;" >
          <div class="right_input">
            <div>
              <el-radio v-model="release.type" label="1">论文</el-radio>
              <el-radio v-model="release.type" label="2" style="margin-left:28px;">图书</el-radio>
              <el-radio v-model="release.type" label="3" style="margin-left:28px;">科技报告</el-radio>
            </div>
            <div style="margin-top:10px;">
              <el-radio v-model="release.type" label="4">网站/软件说明书</el-radio>
              <el-radio v-model="release.type" label="5">广告设计</el-radio>
              <el-radio v-model="release.type" label="6">LOGO制作</el-radio>
            </div>
          </div>
        </div>
      </div>
      <div class="typeIn">
        <div class="inLeft selInputWidth" style="width:172px;">
          <span>*</span>
          <span>成果格式：</span>
        </div>
        <div class="inRight" style="width:470px;">
          <div class="right_input right_input_radio">
            <!-- <el-radio v-model="release.resultformat" label="word">word</el-radio> -->
            <el-radio v-model="release.resultformat" disabled label="pdf">pdf</el-radio>
            <!-- <el-radio v-model="release.resultformat" label="ppt">ppt</el-radio>
            <el-radio v-model="release.resultformat" label="pptx">pptx</el-radio>
            <el-radio v-model="release.resultformat" label="jpg">jpg</el-radio>
            <el-radio v-model="release.resultformat" label="png">png</el-radio> -->
          </div>
        </div>
      </div>

      <template v-if="release.type">
        <div v-for="(item,i) of inputList[release.type-1]" :key="i">
          <div class="typeIn" v-if="item.type=='input'">
            <div class="inLeft selInputWidth">
              <span v-if="item.required">*</span>
              <span>{{item.title}}：</span>
            </div>
            <div class="inRight" style="width:440px;">
              <el-input class="right_input" v-model="inputList[release.type-1][i].value"
               :placeholder="'请输入'+item.title">
              </el-input>
            </div>
          </div>
          <div class="typeIn" v-if="item.type=='radio'">
            <div class="inLeft selInputWidth">
              <span v-if="item.required">*</span>
              <span>{{item.title}}：</span>
            </div>
            <div class="inRight" style="width:440px;">
              <div class="right_input right_input_radio" style="height:auto;">
                <el-radio v-for="(text,t) of allSelect[item.selList]" v-model="inputList[release.type-1][i].value" 
                :label="text" :key="t">{{text}}</el-radio>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>

    <div class="gWbc basicInfo">
      <div class="bi_top_btn">设置商务要求</div>
      <div class="typeIn" style="margin-top:30px">
        <div class="inLeft">
          <span>*</span>
          <span>加工工期：</span>
        </div>
        <div class="inRight" style="width:420px;">
          <div class="inputs">
            <el-input class="right_input" @input="(e)=>{inputNum(e,'totaldays')}"
             v-model="release.totaldays"
             style="width:200px" placeholder="请输入天数(正整数)"></el-input>
            <span style="margin-left:10px">天</span>
          </div>
        </div>
      </div>
      <div class="typeIn">
        <div class="inLeft">
          <span>*</span>
          <span>负责人酬劳：</span>
        </div>
        <div class="inRight">
          <div class="inputs">
            <el-input class="right_input" @input="(e)=>{inputNum(e,'fzr_totalmoney')}"
             style="width:200px" 
            placeholder="请输入知识币数(正整数)" v-model="release.fzr_totalmoney"></el-input>
            <span style="margin-left:10px">知识币</span>
          </div>
        </div>
      </div>
      <div class="typeIn">
        <div class="inLeft">
          <span>*</span>
          <span>任务总酬劳：</span>
        </div>
        <div class="inRight">
          <div class="inputs">
            <el-input class="right_input" @input="(e)=>{inputNum(e,'totalmoney')}" style="width:200px"
             placeholder="请输入知识币数(正整数)" v-model="release.totalmoney"></el-input>
            <span style="margin-left:10px">知识币</span>
          </div>
        </div>
      </div>
      <div class="typeIn in_btn">
        <div class="inLeft_btn" @click="tapCancel">取消</div>
        <div class="inRight_bnt" @click="tapRelease">发布</div>
      </div>
    </div>

  </div>
</template> 

<script>
import ArticleNav from "@/components/article-nav/index.vue";
import { Loading } from 'element-ui';
import {post} from "@/services/http.js";
import { QUERYED,UPDATES,INSERT,DELETE } from "@/services/dao.js"; 
import {Bringbill} from "@/js/common";
export default {
  components: {
    "V-ArticleNav": ArticleNav,
  }, 
  data() {
    return {
      navList:[{word:'发布'}],  //导航
      release:{ //发布      
        taskname:'',  //任务名称
        userid:'',  //发布者ID
        username:'',  //发布者姓名
        describe:'',  //任务简介
        type:'',  //任务加工类型
        resultformat:'pdf',  //成果格式
        totaldays:'',  //任务工期    
        totalmoney:'',  //总酬劳
        fzr_totalmoney:'',  //负责人酬劳
        status:0,   //0未启动，1已逾期，2加工中，3已完成，4已取消
      }, 

      //选择领域
      allSelect:{
        fieldList:['图书情报','电子电工','教育学','机械工程','合成生物学','人工智能','计算机科学','材料科学','其他'],
      },
      // 加工类型的输入的值
      inputType:{},

      //加工类型
      inputList:[
        [  //1)论文类型
          {name:'title',title:'作品名称',type:'input',value:'',required:true},
          {name:'keyword',title:'关键词',type:'input',value:'',required:true},
          {name:'summary',title:'摘要',type:'input',value:''},
          {name:'classification',title:'论文分类号',type:'input',value:'',required:true},
          {name:'subject',title:'领域',type:'radio',selList:'fieldList',value:'',required:true}
        ],
        [   //图书类型
          {name:'title',title:'作品名称',type:'input',value:'',required:true},
          {name:'summary',title:'摘要',type:'input',value:''},
          {name:'subject',title:'领域',type:'radio',selList:'fieldList',value:'',required:true}
        ],
        [  //科技报告
          {name:'title',title:'名称',type:'input',value:'',required:true},
          {name:'summary',title:'摘要',type:'input',value:''},
          {name:'keyword',title:'关键词',type:'input',value:'',required:true},
          {name:'subject',title:'领域',type:'radio',selList:'fieldList',value:'',required:true}
        ],
        [  //软件类说明书
          {name:'title',title:'系统名称',type:'input',value:'',required:true},
          {name:'requirement',title:'系统功能要求',type:'input',value:'',required:true},
          {name:'language',title:'编程语言',type:'input',value:'',required:true},
          {name:'framwork',title:'编程框架',type:'input',value:'',required:true},
          {name:'environment',title:'数据库及运行环境',type:'input',value:'',required:true}
        ],
        [  //广告设计
          {name:'title',title:'广告名称',type:'input',value:'',required:true},
          {name:'product',title:'广告涉及产品',type:'input',value:'',required:true},
          {name:'style',title:'广告风格要求',type:'input',value:'',required:true},
          {name:'textRequirement',title:'广告文案要求',type:'input',value:'',required:true},
          {name:'imageRequirement',title:'广告图片要求',type:'input',value:'',required:true}
        ],
        [  //6)LOGO制作
          {name:'title',title:'LOGO名称',type:'input',value:'',required:true},
          {name:'requirement',title:'包含元素要求',type:'input',value:'',required:true},
          {name:'color',title:'颜色要求',type:'input',value:'',required:true},
          {name:'textRequirement',title:'文字要求',type:'input',value:'',required:true}
        ]
      ],

      residueMoney:0,  //当前余额
      zsjg_prepay:0,  //用户被冻结余额
      loginName:'',  //用户登录名
      LoadingIcon:null,  //加载

      isExist:false,   //任务名称是否重复
    };
  },
  created(){
    this.LoadingIcon = Loading.service()
    this.LoadingIcon.close()
    let userid = this.$store.state.userinfo.id;
    if(!userid){
      this.$alert('请登录后再发布!', '提示', {
        confirmButtonText: '确定',
        callback: () => {
          this.$router.push({path:'/login'})
        }
      });
      return
    }
    this.release.userid = userid
    this.getUserInfo(userid)
  },
  watch:{
    //切换类型时清空输入的数据
    'release.type':function(e){
      if(!e)return
      this.inputType = {}
      for(let item of this.inputList[e-1]){
        this.$set(item,'value','')
      }
    }
  },
  methods: {
    //获取去用户信息
    async getUserInfo(id){
      let res = await QUERYED(
        "post","",
        "User(where:{id: {_eq:"+ id +"}}) { id name Money zsjg_prepay loginName }"
      );
      if(res.success){
        this.release.username = res.data.User[0].name
        this.residueMoney = res.data.User[0].Money
        this.zsjg_prepay = res.data.User[0].zsjg_prepay
        this.loginName  = res.data.User[0].loginName ||'123'
      }
    },

    //点击取消时
    tapCancel(){
      this.$router.go(-1)
    },

    //判断输入的是否为数字 
    inputNum(e,name){
      this.release[name] = e.replace(/[^\d]/g,'' )
    },

    //判断值是否为空
    pdIsEmpty(e){
      return e.replace(/ /g,'')
    },

    //警告弹窗
    msgWarn(message,type){
      this.$message({
        message,type: type||'warning'
      });
    },

    //限制字数
    textLength(e){
      if(e.length>30){
        this.msgWarn('最大长度为30个字')
        this.release.taskname = e.slice(0,30)
      }
    },

    //查看任务名称是否重复
    async textRepetition(e){
      let value = this.pdIsEmpty(e.target.value)
      if(value){
        let res = await QUERYED(
          "post","",
          "jgDigitalPro_aggregate(where:{taskname: {_eq:\""+ value +"\"}}) { aggregate {count}}"
        );
        if(res.success){
          let isExist = res.data.jgDigitalPro_aggregate.aggregate.count>0
          this.isExist = isExist
          if(isExist){
            this.msgWarn('当前任务名称已存在')
          }
        }
      }
    },

    //点击发布时
    tapRelease(){
      let {release,pdIsEmpty,msgWarn,residueMoney,inputList,inputType,isExist} = this
      let {taskname,describe,type,totaldays,totalmoney,fzr_totalmoney} = release
      if(!pdIsEmpty(taskname)){
        msgWarn('请输入任务名称')
        return
      }
      if(isExist){
        msgWarn('当前任务名称已存在')
        return
      }
      if(!pdIsEmpty(describe)){
        msgWarn('请输入任务简介')
        return
      }
      if(!type){
        msgWarn('请选择加工类型')
        return
      }else{
        for(let item of inputList[type-1]){
          inputType[item.name] = item.value
        }
      }
      // if(!resultformat){
      //   msgWarn('请选择成果格式')
      //   return
      // }
      if(type){
        for(let item of inputList[type-1]){
          if(item.required&&!pdIsEmpty(item.value)){
            let title = item.type=='input'?'请输入':'请选择'
            msgWarn(title+item.title)
            return
          }
        }
      }
      if(!totaldays){
        msgWarn('请输入任务工期')
        return
      }
      if(!fzr_totalmoney){
        msgWarn('请输入负责人酬劳')
        return
      }
      if(!totalmoney){
        msgWarn('请输入总酬劳')
        return
      }
      if(fzr_totalmoney*1>totalmoney*1){
        msgWarn('负责人酬劳必须小于总酬劳')
        return
      }
      if(residueMoney*1<totalmoney*1){
        this.$alert(`您的余额不足，当前账户余额为${residueMoney}知识币`, '', {
          confirmButtonText: '确定',
          callback: () => {}
        });
        return
      }
      this.$confirm(`你的账户知识币余额为${(residueMoney*1).toFixed(2)}知识币，您确定要支付${(totalmoney*1).toFixed(2)}知识币发布任务？`, '', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(() => {
        this.LoadingIcon = Loading.service()
        this.tapPay(release,inputType)
      }).catch(() => {
        msgWarn('用户取消支付')
      });
    },

    //用户点击确定支付
    async tapPay(release,inputType){
      let {msgWarn} = this
      let {userid} = release
      let strRelease = ''
      let date = new Date()*1+''
      release.id = date.slice(date.length-9+(userid+'').length,date.length) + userid
      release.issueTime = date*1
      for(let name in release){
        strRelease += name
        if(typeof release[name] == 'string') strRelease = strRelease+':"'+release[name]+'",'    
        else strRelease = strRelease+':'+release[name]+','
      }
      for(let name in inputType){
        strRelease += name
        if(typeof inputType[name] == 'string') strRelease = strRelease+':"'+inputType[name]+'",'    
        else strRelease = strRelease+':'+inputType[name]+','
      } 
      strRelease = strRelease.slice(0,strRelease.length-1)

      //插入发布数据
      let installZSJG = await INSERT(
        "POST","",
        'insert_jgDigitalPro(objects:{'+ strRelease +'}) {affected_rows returning {id}}'
      )
      if(!installZSJG.success){
        this.LoadingIcon.close()
        msgWarn('发布失败，请重试')
        return
      }else{
        this.setTaskTowData(release,inputType,installZSJG.data.insert_jgDigitalPro.returning[0].id)
      }
    },

    //调用课题二新增数据 ——> 数字产品
    setTaskTowData(release,inputType,tsakid){
      let {userid,username,taskname,describe,type,totalmoney,fzr_totalmoney,resultformat,totaldays} = release
      let {title,subject,summary,keyword,classification,product,style,textRequirement,imageRequirement,requirement,language,
      framwork,environment,color} = inputType
      let obj = {
        taskId:tsakid,  //任务ID（课题5的任务ID
        createId:userid,  //任务创建者的ID
        createName:username,   //任务创建者的名称
        taskName:taskname,  //任务名称
        taskInfo:describe,  //任务描述信息
        status:1,
        type,  //(1,"论文"),(2,"图书"),(3,"科技报告 "),(4,"广告设计")，(5,"软件类说明书"),(6,"LOGO制作")
        reward:totalmoney*1-fzr_totalmoney*1,  //悬赏金额 总酬劳 减去 负责人的酬劳
        resultformat,   //成果格式
        totaldays,  //任务工期
        title  //作品名称
      }
      switch (type) {
        case '1':
          obj.subject = subject   //领域（课题五指定的九个领域
          obj.keyword = keyword   //关键词
          obj.summary = summary   //摘要
          obj.classification = classification   //论文分类号
          break;
        case '2':
          obj.subject = subject   //领域（课题五指定的九个领域
          obj.summary = summary   //摘要
          break;
        case '3':
          obj.subject = subject   //领域（课题五指定的九个领域
          obj.keyword = keyword   //关键词
          obj.summary = summary   //摘要
          break;
        case '4':
          obj.product = product    //广告涉及产品
          obj.style = style   //广告风格要求
          obj.textRequirement = textRequirement   //广告文案要求
          obj.imageRequirement = imageRequirement   //广告图片要求
          obj.imageRequirement = imageRequirement   //广告图片要求
          break;
        case '5':
          obj.requirement = requirement    //系统功能要求
          obj.language = language   //编程语言
          obj.framwork = framwork   //编程框架
          obj.environment  = environment    //数据库及运行环境
          break;
        case '6':
          obj.requirement = requirement    //包含元素要求
          obj.color = color   //颜色要求
          obj.textRequirement = textRequirement   //文字要求
          break;
      }
      post('/common/huaFenDigitalProTask',obj).then(res=>{
        if(!res.success){
          this.issueRevocation(tsakid)
        }else{
          this.deductZsb(tsakid,release)
        }
      },(err)=>{
        this.issueRevocation(tsakid)
      })
    },

    //扣除知识币
    async deductZsb(tsakid,release){
      let {residueMoney,zsjg_prepay,msgWarn} = this
      let {userid,totalmoney} = release
      let pay = await UPDATES(
        "post","",
        "update_User(_set: {Money:"+(residueMoney*1-totalmoney*1)+", zsjg_prepay:"+(zsjg_prepay*1+totalmoney*1)+"}, where: {id: {_eq: "+userid+"}}) {  affected_rows }"
      )
      if(!pay.success){
        this.issueRevocation(tsakid)
        // msgWarn('支付失败，请重试')
        return  
      }
      this.LoadingIcon.close()
      msgWarn('发布成功','success')
      Bringbill.call(this).then(orderNum=>{
        this.installOrder(orderNum,totalmoney,userid,tsakid)
      })
      this.setData(tsakid)
    },

    //撤回数据
    async issueRevocation(tsakid){
      this.LoadingIcon.close()
      await DELETE(
        "post","",
        'delete_jgDigitalPro(where: {id:{_eq:'+tsakid+'}}) {affected_rows}'
      )
      this.msgWarn('发布失败，请重试')
    },

    //新增订单号
    async installOrder(orderNum,totalmoney,userid,tsakid){
      await INSERT(
        "POST","",
        'insert_bill(objects:{time:"'+
          new Date()
        +'",type:4,orderNum:"'+
          orderNum
        +'",Price:"'+
          totalmoney+
        '",classification:1,userid:'+
          userid
        +'orderid:'+
          tsakid
        +',state:0}) {affected_rows}'
      )
    },

    //跳转详情页
    setData(tsakid){
      // let id = this.release.userid
      // this.release={ 
      //   taskname:'',userid:'',username:'',describe:'',type:'',resultformat:'',totaldays:'',totalmoney:'',status:0
      // }, 
      // this.inputType = {}
      // this.getUserInfo(id)
      this.$router.push({path:'/taskDetail',query:{id:tsakid,type:1,isNotBack:1}})
    }
  }
};
</script>

<style scoped>
.container {
  background-color: #EEEEEE;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.headClass {
  width: 1090px;
  height: 66px;
  line-height: 66px;
  text-align: left;
}
.gWbc{
  width: 1090px;
  background-color: #fff;
}
.title{
  height: 72px;
  line-height: 72px;
  text-align: center;
  color: #000000;
  font-size: 20px;
  font-weight: bold;
  border-bottom: 3px solid #eee;
}
.basicInfo{
  /* margin-bottom: 30px; */
  padding-top: 18px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 3px solid #eee;
}
.bi_top_btn{
  width: 380px;
  height: 36px;
  background: #EEEEEE;
  border: 1px solid #D2D2D2;
  text-align: center;
  line-height: 36px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
}
.typeIn{
  margin-top: 17px;
  display: flex;
}
.inLeft{
  padding-top: 8px;
  text-align: right;
  font-family: Microsoft YaHei;
  font-weight: 400;
  display: flex;
  align-items:flex-start;
}
.inLeft>span:first-child{
  color: #D50005;
  font-size: 20px;
}
.inLeft>span:last-child{
  margin-left: 10px;
  font-size: 14px;
  color: #000000;
}
.selInputWidth{
  min-width: 145px;
  display: flex;
  justify-content: flex-end;
}
.inRight{
  width: 390px;
  margin-left: 10px;
}
.select_input >>> .el-cascader{
  width: 100%;
}
.right_input{
  width: 100%;
  height: 36px;
}
.right_input_radio{
  line-height: 36px;
}
.rightTs{
  margin-top: 10px;
  font-size: 14px;
  text-align: left;
  font-family: Microsoft YaHei;
  font-weight: normal;
  color: #676767;
}
.rightTs>div{
  margin-left: 42px;
}
.right_input>>>.el-textarea__inner{
  height: 100px;
}
.inputs{
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.inputs_btn{
  width: 25px;
  height: 25px;
  margin-left: 20px;
  border:1px solid #999;
  border-radius: 3px;
  text-align: center;
  line-height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.inputs_btn>i{
  font-size: 16px;
  color: #000000;
}
.errors{
  align-items: flex-end;
}
.in_btn{
  width: 700px;
  margin-top: 70px;
  justify-content: space-between;
}
.in_btn>div{
  width: 130px;
  height: 36px;
  background-color: #EEEEEE;
  border: 1px solid #D2D2D2;
  border-radius: 5px;
  text-align: center;
  line-height: 36px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  color: #011603;
  cursor: pointer;
}
.in_btn>div:hover{
  opacity: 0.8;
}
div.inRight_bnt{
  background-color: #0084FF;
  border: 1px solid #0084FF; 
  color:#fff
}
</style>
